import React from "react";
import theme from "theme";
import { Theme, Image, Box, Strong, Text, Link, Section, Icon, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosHeart, IoIosArrowRoundForward, IoMdHeart, IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				SKILLENHANCE
			</title>
			<meta name={"description"} content={"Школа, яка допоможе вам оволодіти англійською мовою незалежно від вашого рівня знань"} />
			<meta property={"og:title"} content={"SKILLENHANCE"} />
			<meta property={"og:description"} content={"Школа, яка допоможе вам оволодіти англійською мовою незалежно від вашого рівня знань"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link3" />
			<Override slot="box" />
			<Override slot="text">
				skillenhance
			</Override>
		</Components.Header2>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"
					object-fit="contain"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15%3A22%3A07.502Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15%3A22%3A07.502Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15%3A22%3A07.502Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15%3A22%3A07.502Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15%3A22%3A07.502Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15%3A22%3A07.502Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15%3A22%3A07.502Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Школа, яка допоможе вам оволодіти англійською мовою незалежно від вашого рівня знань
					</Strong>
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Наша мета – забезпечити вас знаннями, які відкриють перед вами нові можливості: від успішної кар’єри до комфортних подорожей світом.
				</Text>
				<Link
					href="#"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Дізнатися більше
				</Link>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					В чому наша перевага?
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Всі наші тренери – носії мови або кваліфіковані спеціалісти з міжнародними сертифікатами.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosHeart}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline4" color="--light" margin="0px 0px 6px 0px">
							Індивідуальний підхід
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Кожен курс адаптований під ваші потреби.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
						href="/pro-nas"
					>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="space-between"
					font="--headline4"
				>
					<Icon
						category="io"
						icon={IoIosHeart}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline4" color="--light" margin="0px 0px 6px 0px">
							Сучасні методики
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Використовуємо інтерактивні матеріали, відеоуроки, аудіозаписи, тестування та практичні завдання.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
						href="/pro-nas"
					>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoMdHeart}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline4" color="--light" margin="0px 0px 6px 0px">
							Доступність
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Курси доступні в будь-який час та з будь-якого пристрою.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
						href="/pro-nas"
					>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" quarkly-title="Content-6">
			<Override
				slot="SectionContent"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				width="100%"
				min-width="100%"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6744384ce778710018d530d5/images/flag_of_uk-400x400.png?v=2024-11-25T15:22:07.476Z) 50% 50% /cover repeat scroll padding-box"
				lg-height="600px"
				md-height="500px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				padding="80px 60px 80px 60px"
				sm-padding="50px 25px 80px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						font="--headline2"
					>
						Як працює SkillEnhance24.live?
					</Strong>
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 16px/27px --fontFamily-sans" color="#505257">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Реєстрація:
						</Strong>
						{" "}Створіть обліковий запис, заповніть профіль і оберіть потрібний курc, зателефонувавши нам.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 16px/27px --fontFamily-sans" color="#505257">
						<Strong>
							Проходження тесту:
						</Strong>
						{" "}Ми визначимо ваш рівень знань і порекомендуємо відповідну програму.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 16px/27px --fontFamily-sans" color="#505257">
						<Strong>
							Навчання:
						</Strong>
						{" "}Виконуйте завдання, переглядайте уроки та отримуйте фідбек від викладачів.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 16px/27px --fontFamily-sans" color="#505257">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Досягайте результатів:
						</Strong>
						{" "}Покращуйте знання англійської та отримуйте сертифікати, які підтвердять ваші успіхи.
					</Text>
				</Box>
				<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					<Strong>
						Скільки триває курс?
					</Strong>
				</Text>
				<Text margin="0px 0px 2rem 0px" font="normal 300 16px/1.5 --fontFamily-sans" color="#505257">
					Тривалість залежить від обраної програми. Наприклад, базовий курс триває 3 місяці, а підготовка до іспитів – до 6 місяців.
				</Text>
				<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					<Strong>
						Чи можна отримати сертифікат?
					</Strong>
				</Text>
				<Text margin="0px 0px 2rem 0px" font="normal 300 16px/1.5 --fontFamily-sans" color="#505257">
					Так, після успішного завершення курсу ви отримаєте сертифікат міжнародного зразка.
				</Text>
				<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					<Strong>
						Скільки коштують курси?
					</Strong>
				</Text>
				<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sans" color="#505257">
					Ціна залежить від тривалості та складності курсу. Ми пропонуємо вигідні пакети та регулярні знижки.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text margin="0px 0px 24px 0px" color="--dark" font="--headline2" lg-text-align="center">
					<Strong font="--headline2">
						Почніть навчання вже сьогодні!
					</Strong>
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Не втрачайте можливості вдосконалити свої знання та розширити горизонти. Реєструйтеся на SkillEnhance24.live і зробіть перший крок до вашого успіху.
				</Text>
				<Link
					href="/pro-nas"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Дізнатися більше
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/6744384ce778710018d530d5/images/learning-english-importance.webp?v=2024-11-25T15:22:07.486Z"
					object-fit="contain"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/learning-english-importance.webp?v=2024-11-25T15%3A22%3A07.486Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/learning-english-importance.webp?v=2024-11-25T15%3A22%3A07.486Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/learning-english-importance.webp?v=2024-11-25T15%3A22%3A07.486Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/learning-english-importance.webp?v=2024-11-25T15%3A22%3A07.486Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/learning-english-importance.webp?v=2024-11-25T15%3A22%3A07.486Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/learning-english-importance.webp?v=2024-11-25T15%3A22%3A07.486Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/learning-english-importance.webp?v=2024-11-25T15%3A22%3A07.486Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
			<Override slot="image" />
			<Override slot="link6" />
			<Override slot="link5" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});